// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const cart = () => import('./views/cart')
const tableCart = () => import('./views/tableCart')
const payments = () => import('./views/paymentMethod')
const address = () => import('./views/selectAddress')

const offlineCart = () => import('./views/offlineCart.vue')
// const success = () => import('./views/success')

// start routes
export default [
  {
    path: '/cart',
    name: 'cart',
    component: curvaLayout,
    meta: { name: 'cart', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'cart',
      component: cart,
      children: [
        {
          path: '',
          name: 'cart',
          component: tableCart,
          meta: {
            name: 'cart',
            layout: 'nav-with-menu',
            breadCrumb: [
              {
                text: 'cart.cart',
                active: true
              }
            ]
          }
        },
        {
          path: 'address',
          name: 'address',
          component: address,
          meta: {
            name: 'address',
            layout: 'nav-with-menu',
            breadCrumb: [
              {
                text: 'cart.cart',
                to: 'cart'
              },
              {
                text: 'cart.address',
                active: true
              }
            ]
          }
        },

        {
          path: 'payments',
          name: 'payments',
          component: payments,
          meta: {
            name: 'payments',
            layout: 'nav-with-menu',
            breadCrumb: [
              {
                text: 'cart.cart',
                to: 'cart'
              },
              {
                text: 'cart.address',
                to: 'address'
              },
              {
                text: 'cart.payment',
                active: true
              }
            ]
          }
        }
      ]
    }]
  },
  {
    path: '/my-cart',
    name: 'myCart',
    component: curvaLayout,
    meta: { name: 'myCart', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'myCart',
      component: offlineCart
    }]
  }
  // {
  //   path: '/success/:id',
  //   name: 'success',
  //   component: curvaLayout,
  //   props: true,
  //   meta: { name: 'success', layout: 'nav-with-menu' },
  //   children: [{
  //     path: '',
  //     props: true,
  //     name: 'success',
  //     component: success,
  //     breadCrumb: [
  //       {
  //         text: 'main.congratulations',
  //         active: true
  //       }
  //     ]
  //   }]
  // }
]

// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const search = () => import('./views/search')
const findProduct = () => import('./views/findProduct')
const suggestProduct = () => import('./views/suggest')

// start routes
export default [
  {
    path: '/search',
    name: 'search',
    component: curvaLayout,
    meta: { name: 'contact-us', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'search',
      component: search,
      meta: {
        name: 'search',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'search.search',
            active: true
          }
        ]
      }
    }]
  },
  {
    path: '/find-product',
    name: 'findProduct',
    component: curvaLayout,
    meta: { name: 'findProduct', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'findProduct',
      component: findProduct,
      meta: {
        name: 'findProduct',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'productDetails.findProduct',
            active: true
          }
        ]
      }
    }]
  },
  {
    path: '/suggest-product',
    name: 'suggestProduct',
    component: curvaLayout,
    meta: { name: 'findProduct', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'suggestProduct',
      component: suggestProduct,
      meta: {
        name: 'suggestProduct',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'productDetails.suggestProduct',
            active: true
          }
        ]
      }
    }]
  }
]

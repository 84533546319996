// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const contactUs = () => import('./views/contact-us')
const hiring = () => import('./views/hiring')
const hiringDetails = () => import('./views/hiringForm')
const complaints = () => import('./views/complaints')

// start routes
export default [
  {
    path: '/contact-us',
    name: 'contact-us',
    component: curvaLayout,
    meta: { name: 'contact-us', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'contact-us',
      component: contactUs,
      meta: {
        name: 'contact-us',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'mainNav.contactUs',
            active: true
          }
        ]
      }
    },
    {
      path: 'hiring',
      name: 'hiring',
      component: hiring,
      meta: {
        name: 'hiring',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'mainNav.hiring',
            active: true
          }
        ]
      }
    },
    {
      path: 'hiring-details/:id',
      name: 'hiringDetails',
      component: hiringDetails,
      meta: {
        name: 'hiring',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'mainNav.hiring',
            active: true
          }
        ]
      }
    },
    {
      path: 'complaints',
      name: 'complaints',
      component: complaints,
      meta: {
        name: 'hiring',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'mainNav.complaints',
            active: true
          }
        ]
      }
    }]
  }
]

<template class="text-style">
  <span class="font-size-16">
    <span v-for="(part, key) in title.split(' ')" :key="key">
      <span v-if="key < title.split(' ').length-1" class="title-colored-part">{{ part }} </span>
      <span v-else class="title-mode-part">{{ part }}</span>
    </span>
  </span>
</template>
<script>
export default {
  props: {
    title: {
      type: String
    }
  }
}
</script>
<style>
.text-style {
  font-family: 'Neo Sans Arabic';
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-transform: capitalize;
}
.title-colored-part {
  color: var(--iq-granola-yellow);
  font-size: 28px;
}
</style>

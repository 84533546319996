<template>
  <b-dropdown class="curva-dropdown" offset="-50">
    <template #button-content>
      <span><i :class="symbol"></i> {{ $t('sort') }} </span>
    </template>
    <b-dropdown-item class="dropdown-item" v-for="(option, key) in options" :key="key"
                     :value="option.value" @click="$emit('select', option.value)">
      {{ option.key }}</b-dropdown-item>
  </b-dropdown>
</template>
<script>
export default {
  props: {
    symbol: {
      type: String,
      default: 'fas fa-sort-alpha-down'
    }
    /* name: {
      type: String,
      default: 'Sort'
    }, */
  },
  data () {
    return {
      options: [
        {
          key: this.$t('filter.acc'),
          value: 'intial_price&dir=asc'
        },
        {
          key: this.$t('filter.dec'),
          value: 'intial_price&dir=desc'
        },
        {
          key: this.$t('filter.sorted'),
          value: 'name&dir=asc'
        },
        {
          key: this.$t('filter.newest'),
          value: 'id&dir=desc'
        },
        {
          key: this.$t('filter.oldest'),
          value: 'id&dir=asc'
        }
      ]
    }
  }
}
</script>

export default {
  state: {
    cart: JSON.parse(localStorage.getItem('cart')) || []
  },
  mutations: {
    ADD_TO_CART (state, product) {
      const item = state.cart.find(i => i.barcode === product.barcode)
      if (item) {
        item.quantity = product.quantity
      } else {
        state.cart.push({ ...product })
      }
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    REMOVE_FROM_CART (state, barcode) {
      state.cart = state.cart.filter(item => item.barcode !== barcode)
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    CLEAR_CART (state) {
      state.cart = []
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    CHANGE_COUNT (state, { barcode, quantity }) {
      const item = state.cart.find(i => i.barcode === barcode)
      if (item && quantity > 0) {
        item.quantity = quantity
      } else if (item && quantity <= 0) {
        // Remove item if quantity is set to zero or below
        state.cart = state.cart.filter(i => i.barcode !== barcode)
      }
      localStorage.setItem('cart', JSON.stringify(state.cart))
    }
  },
  getters: {
    cartItems: state => state.cart,
    cartTotalItems: state => state.cart.length,
    cartTotalOffline: state => state.cart.reduce((total, item) => total + item.price * item.quantity, 0),
    cartCount: state => state.cart.reduce((count, item) => count + item.quantity, 0)
    // cartTotalPrice: state => state.cart.reduce((total, item) => total + item.price * item.quantity, 0)
  },
  actions: {
    addToCartOffline ({ commit }, product) {
      commit('ADD_TO_CART', product)
    },
    removeFromCartOffline ({ commit }, barcode) {
      commit('REMOVE_FROM_CART', barcode)
    },
    clearCartOffline ({ commit }) {
      commit('CLEAR_CART')
    },
    changeCount ({ commit }, payload) {
      commit('CHANGE_COUNT', payload)
    }
  }
}

// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const offers = () => import('./views/offers')

// start routes
export default [
  {
    path: '/offers',
    name: 'offers',
    component: curvaLayout,
    meta: { name: 'offers', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'offers',
      component: offers,
      meta: {
        name: 'offers',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'mainNav.offers',
            active: true
          }
        ]
      }
    }]
  }
]

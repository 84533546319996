// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const videos = () => import('./views/videos')
const photos = () => import('./views/photos')

// start routes
export default [
  {
    path: '/multimedia',
    name: 'multimedia',
    component: curvaLayout,
    meta: { name: 'multimedia', layout: 'nav-with-menu' },
    children: [
      {
        path: 'videos',
        name: 'videos',
        component: videos,
        meta: {
          name: 'videos',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'mainNav.multimedia'
            },
            {
              text: 'mainNav.videos',
              active: true
            }
          ]
        }
      },
      {
        path: 'photos',
        name: 'photos',
        component: photos,
        meta: {
          name: 'photos',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'mainNav.multimedia'
            },
            {
              text: 'mainNav.images',
              active: true
            }
          ]
        }
      }
    ]
  }
]

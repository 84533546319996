import axios from 'axios'
/* import router from './router/index' */
import { core } from '@/config/pluginInit'
export default () => {
  const apiClient = axios.create({
    // baseURL: 'https://api.curvaegypt.com/public/api/',
    baseURL: 'https://octane.curvaegypt.com/api/',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null}`,
      Accept: 'application/json',
      'Accept-Language': localStorage.getItem('lang') || 'ar'
    }
  })
  apiClient.interceptors.response.use((response) => {
    /* console.clear() */
    return response
  }, (error) => {
    if (error.response.status === 401) {
      core.showSnackbar('error', 'You must login')
    } else {
      if (error.response.data.errors) {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(error.response.data.errors)) {
          core.showSnackbar('error', value)
        }
      } else if (error.response.data) {
        core.showSnackbar('error', error.response.data.message)
      }
    }
    /* console.clear() */
    return Promise.reject(error)
  })
  return apiClient
}

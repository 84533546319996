// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const blogs = () => import('./views/blogs')
const blogDetails = () => import('./views/blog-details')

// start routes
export default [
  {
    path: '/blogs',
    name: 'blogs',
    component: curvaLayout,
    meta: { name: 'blogs', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'blogs',
      component: blogs,
      meta: {
        name: 'blogs',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'mainNav.blogs',
            active: true
          }
        ]
      }
    },
    {
      path: 'blog-details/:id',
      name: 'blogDetails',
      component: blogDetails,
      meta: {
        name: 'blogDetails',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'Blogs',
            to: 'blogs'
          },
          {
            text: 'Blog Details',
            active: true
          }
        ]
      }
    }
    ]
  }
]

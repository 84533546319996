import Api from '@/axios'

export default {
  getCart (page = 1) {
    return Api().get('users/carts')
  },
  addCart (id, payload) {
    return Api().post(`users/carts/${id}`, payload)
  },
  removeCart (id) {
    return Api().delete(`users/carts/${id}`)
  },
  changeQuantity (id, payload) {
    return Api().post(`users/carts/quantity/${id}`, payload)
  },
  placeOrder (payload) {
    const getComeFrom = sessionStorage.getItem('come_from') || 'web'
    return Api().post('users/orders/cart', { ...payload, come_from: getComeFrom })
  },
  guestOrder (payload) {
    return Api().post('guest-order', payload)
  }
}

// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const categories = () => import('./views/categories')

// start routes
export default [
  {
    path: '/categories',
    name: 'categories',
    component: curvaLayout,
    meta: { name: 'categories', layout: 'nav-with-menu' },
    children: [{
      path: ':category',
      name: 'categories',
      component: categories,
      meta: {
        name: 'categories',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'mainNav.categories',
            active: true
          }
        ]
      }
    }]
  }
]

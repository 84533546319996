<template>
  <router-link :to="{name: 'categories', params: {category: category.router}}">
    <b-card
      class="category-card-container mb-2 cursor-pointer"
    >
      <b-card-body class="category-body p-0">
        <div class="frame position-relative d-flex justify-content-center align-items-center mb-0 w-100" >
          <img class="shape-path" :src="vector">
          <img class="front-image" :src="category.image"/>
        </div>
        <p class="text-center font-size-18 font-weight-400">{{ category.title }}</p>
      </b-card-body>
    </b-card>
  </router-link>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      default: () => {
        return {
          title: 'Default title',
          image: require('@/assets/images/curva/categories-images/footballWear.png'),
          router: ''
        }
      }
    }
  },
  data () {
    return {
      vector: require('@/assets/images/curva/dark/Vector.svg')
    }
  }
}
</script>

<style>
.frame {
  height: 197px;
}
@media only screen and (max-width: 500px) {
  .frame {
    height: 140px;
  }
  .front-image {
    width: 80px;
  }
  .shape-path {
    width: 110px;
    height: 110px;
  }
}
.category-card-container {
  border: 1px solid transparent !important;
  border-radius: 10px !important;
  overflow: hidden;
}
.category-body {
  border-radius: 15px !important;
  overflow: hidden;
}
.category-card-container:hover {
  background: linear-gradient(180deg, rgba(179,148,85,1), rgba(179,148,85,0), rgba(179,148,85,1)) !important;
}
</style>

// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const productDetails = () => import('./view/productDetails')
const purchaseProduct = () => import('./view/purchaseProduct')

// start routes
export default [
  {
    path: '/products',
    name: 'products',
    component: curvaLayout,
    meta: { name: 'products', layout: 'nav-with-menu' },
    children: [{
      path: ':id',
      name: 'productDetails',
      component: productDetails,
      meta: {
        name: 'productDetails',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'productDetails.products',
            to: 'categories',
            params: { category: 1 }
          },
          {
            text: 'productDetails.productDetails',
            active: true
          }
        ]
      }
    },
    {
      path: 'purchase-product/:id',
      name: 'purchaseProduct',
      component: purchaseProduct,
      props: true,
      meta: {
        name: 'purchaseProduct',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'productDetails.products',
            to: 'categories',
            params: { category: 1 }
          },
          {
            text: 'productDetails.productDetails',
            to: 'productDetails'
          },
          {
            text: 'productDetails.purchase',
            active: true
          }
        ]
      }
    }
    ]
  }
]

<template>
  <router-link :to="{name: 'blogDetails', params: { id: blog.id }}">
    <b-card
      class="blog-card-container mb-2"
      :img-src="blog.image"
      img-alt="Image"
      img-top
      tag="article"
    >
      <b-card-body class="p-0">
        <p class="blog-date text-left">{{ $t('blogCard.posted') }} {{ formattedDate }}</p>
        <p class="blog-title text-right">{{ blog.name }}</p>
      </b-card-body>
    </b-card>
  </router-link>
</template>

<script>
export default {
  props: {
    blog: {
      type: Object,
      default: () => {
        return {
          title: 'Default title',
          date: '10 Jan, 2022',
          time: '10:30 PM',
          cover: require('@/assets/images/curva/blog-default.png')
        }
      }
    }
  },
  data () {
    return {
      formattedDate: ''
    }
  },
  methods: {
    formatTime (time) {
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]
      time.splice(4)
      if (time.length > 1) {
        time = time.slice(1)
        time[5] = +time[0] < 12 ? ' AM' : ' PM'
        time[0] = +time[0] % 12 || 12
      }
      return time.join('')
    },
    formatDate (blogDate) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const date = new Date(blogDate)
      this.formattedDate = date.getDay() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear()
      this.formattedDate += ' - ' + this.formatTime(('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2))
    }
  },
  created () {
    this.formatDate(this.blog.date)
  }
}
</script>

<style>
.blog-card-container {
  border-radius: 15px !important;
  overflow: hidden;
}
.blog-date {
  font-size: 14px;
  font-width: 400;
}
.blog-title {
  font-size: 20px;
  font-weight: 400;
}
</style>
